import React, { useState } from 'react';

const Tab = ({ tabs, onTabSelect }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].value); 

  const handleTabClick = (value) => {
    setActiveTab(value);
    onTabSelect(value); 
  };

  return (
    <div className="tabs-container"> 
      <ul className="nav nav-tabs" id="tab-cgp">
        {tabs.map((tab, index) => (
          <li key={index} className="nav-item">
            <div
              className={`nav-link ${activeTab === tab.value ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault(); 
                handleTabClick(tab.value);
              }}
            >
              {tab.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tab;
