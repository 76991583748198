import React from 'react'
import Icon from '../elements/Icon'

const HomeVideo = () => {
  return (
    <div id='home-video'>
      <div className='video-text flex justify-center align-center direction-column'>
        <Icon icon='bi bi-film' size='50px'/>
        <h6>CLUB DE GOLF PACHUCA</h6>
      </div>
    </div>
  )
}

export default HomeVideo