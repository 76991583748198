import React from 'react'

const HomeIntroduction = () => {
  return (
    <div id='home-introduction'>
      <div className='introduction-text'>
        <h1>Un legado tangible de la influencia británica en la hermosa capital de Hidalgo.</h1>
        <p>¡Bienvenidos al primer campo de Golf en México! Este distinguido oasis deportivo se erige como un legado tangible de la influencia británica en la región de Hidalgo. Con más de un siglo de historia, nuestro campo de golf no solo es un lugar para disfrutar de este apasionante deporte, sino también un testimonio vivo de la rica herencia cultural que ha perdurado a lo largo de los años.</p>
        <p>Cada rincón de este histórico terreno respira la elegancia y el encanto que caracterizan a los campos de golf clásicos, ofreciendo a nuestros miembros y visitantes una experiencia única y un viaje al pasado donde el golf se convierte en una expresión de tradición y distinción.</p>
        <p>¡Les extendemos una cordial bienvenida a este icónico rincón, donde la tradición y la pasión por el golf se entrelazan para seguir escribiendo la historia del Golf en México!</p>
      </div>
    </div>
  )
}

export default HomeIntroduction