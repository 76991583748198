import React from 'react'

const TournamentHeader = () => {
  return (
    <div className='container' id='tournament-header'>
      <div className='row'>
        <div className='col-12'>
          <div className='flex direction-column align-center'>
            <img className='tournament-title-img' src='/images/clublogo.png'/>
            <h1>Toneo Anual Azprotec 2024</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TournamentHeader