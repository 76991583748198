import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/layouts/Home';
import Tournament from './components/modules/Tournament';
import Header from './components/elements/Header';
import DeleteUser from './components/layouts/Tournament/DeleteUser';
import TermsAndConditions from './components/layouts/Tournament/TermsAndConditions';

const Router = () => {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/torneo-anual" element={ <Tournament /> } />
        <Route path="/borrarusuario" element={ <DeleteUser /> } />
        <Route path="/legales" element={ <TermsAndConditions /> } />
      </Routes>
    </BrowserRouter>
  )
}

export default Router