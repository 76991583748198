export const TOURNAMENT_CATEGORIES = [
  {
    value: 'AA',
    name: 'Categoría AA'
  },
  {
    value: 'A',
    name: 'Categoría A'
  },
  {
    value: 'B',
    name: 'Categoría B'
  },
  {
    value: 'C',
    name: 'Categoría C'
  },
  {
    value: 'D',
    name: 'Categoría D'
  },
  {
    value: 'E',
    name: 'Categoría E'
  },
  {
    value: 'Seniors',
    name: 'Categoría Seniors'
  },
  {
    value: 'Damas',
    name: 'Categoría Damas'
  }
]