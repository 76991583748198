export const ApiRequestInterceptor = async (url, method, body, header = {}) => {

  try {
    const params = ApiRequestParamsFormatter(method, body, header);
    const response = await fetch(url, params);
    return ApiRequestFormatter(response);
  } catch (exception) {
    //TODO Handle Exception Pendient
    return {
      error: 500,
      data: null,
    };
  } finally {
    //TODO Handle Loading
  }
};

const ApiRequestParamsFormatter = (method, body, header) => {
  const headers = ApiRequestHeaderFormatter(header);

  return method !== "GET" && body
    ? {
        method,
        body: JSON.stringify(body),
        headers,
      }
    : { method, headers };
};

const ApiRequestFormatter = async (response) => {
  const code = response.status;
  let data = null;

  if (code === 200 || code === 201 || code === 400) {
    data = await response.json();
  }

  return { code, data };
};

const ApiRequestHeaderFormatter = (header) => {
  let headers = new Headers();

  for (const key in header) {
    headers.append(key, header[key]);
    headers.append("Content-Type", "application/json");
  }

  return headers;
};
