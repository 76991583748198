import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { playersByCategorieFetcher } from '../../../services/fetchers/playersByCategorieFetcher';
import Tab from '../../elements/Tab';
import { TOURNAMENT_CATEGORIES } from '../../../constants/json/tournament-categories';
import TablePlayers from './TablePlayers';
import Loader from '../../elements/Loader';

const Scoreboard = () => {
  const dispatch = useDispatch();
  const players = useSelector((state) => state.players.players);
  const playersLoading = useSelector((state) => state.players.loading);
  const playersError = useSelector((state) => state.players.error);
  const [categorie, setCategorie] = useState('AA')

  useEffect(() => {
    getPlayers();
  }, [categorie]);

  useEffect(() => {
    console.log(players, 'players');
  }, [players]);

  const getPlayers = () => {
    dispatch(playersByCategorieFetcher(categorie))
  }

  return (
    <div className='container'>
      <div className='row mt-3'>
        <div className='col-12 flex justify-center'>
          <h1>Scoreboard</h1>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-12'>
          <Tab tabs={TOURNAMENT_CATEGORIES} onTabSelect={(value) => setCategorie(value)} />
          {
            
            (playersLoading || playersError) ? <Loader /> :
            <TablePlayers players={players} />
          }
        </div>
      </div>
    </div>
  )
}

export default Scoreboard