import { ApiRequestInterceptor } from "./api-request";

const BASE_URL = process.env.REACT_APP_STRAPI_CGP;

export const StrapiInterceptor = async (path, method, body, headers) => {
  return await ApiRequestInterceptor(`${BASE_URL}/${path}`, method, body, {
    ...headers, // Spread passed headers first
    // Then add default header
  });
};
