import React from 'react'

const TablePlayers = ({players}) => {

  function sumThreeStrings(str1, str2, str3) {
    
    const num1 = parseFloat(str1);
    const num2 = parseFloat(str2);
    const num3 = parseFloat(str3);

    if (isNaN(num1) || isNaN(num2) || isNaN(num3)) {
      return "DQ"; 
    }
    return num1 + num2 + num3;
  }
  
  const updatedPlayers = players
  .map(player => ({
    ...player, // Spread the player object
    score_total: sumThreeStrings(player.score_dia_1, player.score_dia_2, player.score_dia_3) 
  }))
  .sort((a, b) => a.score_total - b.score_total); 


  return (
    <div id="table-results">
      <div className="table-responsive">
        <table className="table table-striped mt-3">
          <thead>
            <tr>
              <th>Posición</th>
              <th>Nombre</th>
              <th>Score día 1</th>
              <th>Score día 2</th>
              <th>Score día 3</th>
              <th>Score total</th>
            </tr>
          </thead>
          <tbody>
            {updatedPlayers.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.jugador}</td>
                <td>{item?.score_dia_1}</td>
                <td>{item?.score_dia_2}</td>
                <td>{item?.score_dia_3}</td>
                <td style={{fontWeight: 'bold'}}>{item?.score_total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TablePlayers