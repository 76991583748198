import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ text, link }) => {
  return (
    <Link to={link ?? ''} className='cgp-button-wrapper'>
      <div className='cgp-button'>
        <div className='cgp-button-text'>{text}</div>
      </div>
    </Link>
  )
}

export default Button