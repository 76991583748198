import { createSlice } from "@reduxjs/toolkit";
import { playersByCategorieFetcher } from "../../../services/fetchers/playersByCategorieFetcher";

const initialState = {
  players: [],
  loading: false,
  error: null
};

const playersByCategorieSlice = createSlice({
    name: "players",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(playersByCategorieFetcher.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(playersByCategorieFetcher.fulfilled, (state, action) => {
                state.loading = false;
                state.players = action.payload;
            })
            .addCase(playersByCategorieFetcher.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default playersByCategorieSlice.reducer;
