import React from 'react'
import TournamentHeader from '../layouts/Tournament/TournamentHeader'
import Scoreboard from '../layouts/Tournament/Scoreboard'

const Tournament = () => {
  return (
    <div>
      <TournamentHeader/>
      <Scoreboard />
    </div>
  )
}

export default Tournament