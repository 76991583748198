// CarouselComponent.js
import React from 'react';
import Button from './Button';

const MainCarousel = ({ images }) => {
  return (
    <div id="main-carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#main-carousel"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-current={index === 0 ? 'true' : undefined}
            aria-label={`Slide ${index + 1}`}
          />
        ))}
      </div>
      <div className="carousel-inner">
        {images.map((image, index) => (
          <>
            <div
              key={index}
              className={`carousel-item ${index === 0 ? 'active' : ''}`}
              style={{ backgroundImage: `url(${image.src})` }}
            >
            </div>
            <div className='button-wrapper'>
              <Button text='Ver más' link={image.link}/>
            </div>
          </>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#main-carousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#main-carousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default MainCarousel;
