export const CHARACTERISTICS = [
  {
    icon: 'bi bi-pencil-square',
    title: 'CONCEPTO Y DISENO',
    descripcion: 'Campo de 9 hoyos, que, al completar dos recorridos, totalizan 7178 yardas para abrazar la experiencia completa de 18 hoyos'
  },
  {
    icon: 'bi bi-person-video2',
    title: 'PROFESIONALES DE GOLF',
    descripcion: '3 profesionales de golf que nos ayudan a monitorear el campo y están en disposición de dar clases a nuestros golfistas'
  },
  {
    icon: 'bi bi-award',
    title: 'CLASES Y CLINICAS',
    descripcion: 'Clases, clínicas abiertas para niños y adultos, principiantes (sin ninguna experiencia), medios y avanzados'
  },
  {
    icon: 'bi bi-door-open',
    title: 'CAMPO ABIERTO',
    descripcion: 'Club de Golf Pachuca es un campo de golf con concepto abierto al público'
  },
  {
    icon: 'bi bi-binoculars',
    title: 'IMPRESIONANTES VISTAS',
    descripcion: 'Vistas espectaculares de la bella airosa desde todos sus hoyos, siendo un increíble lugar de naturaleza incomparable'
  },
  {
    icon: 'bi bi-water',
    title: 'LAGOS',
    descripcion: 'El campo de golf cuenta con lagos en cada uno de los hoyos, que armonizan, embellecen y complican el entorno del campo'
  },
  {
    icon: 'bi bi-cup-straw',
    title: 'RESTAURANTE Y HOYO 19',
    descripcion: 'Comida gourmet y Hoyo 19 para pasar un excelente momento o simplemete disfrutar de una buena comida.'
  },
  {
    icon: 'bi bi-emoji-grin',
    title: 'AMENIDADES',
    descripcion: 'El club cuenta con alberca, 2 canchas de tenis, baños de vapor, sauna, regaderas y area infantil'
  }
]