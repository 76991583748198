import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOURNAMENT_PLAYERS } from "../../config/endpoints/tournamentPlayers";
import { StrapiInterceptor } from "../interceptors/api-strapi-interceptor";

export const playersByCategorieFetcher = createAsyncThunk(
  'fetchPlayersCategorie',
  async (categorie) => {
      try {
        const response = await StrapiInterceptor(
          TOURNAMENT_PLAYERS.players_by_categorie.replace('{categorie}', categorie),
          "GET",
        );
          return response.data;
      } catch (error) {
          throw error;
      }
  }
);