import React from 'react'
import Icon from '../elements/Icon'
import { CHARACTERISTICS } from '../../constants/json/characteristics'

const Characteristics = () => {
  return (
    <div id='characteristics' className='container'>
      <div className='row'>
        <div className='col-12 flex justify-center mt-5'>
          <h1>Caracteristicas</h1>
        </div>
      </div>
      <div className='row'>
        {
          CHARACTERISTICS.map((card, i) => (
            <div className='col-6 col-md-3 flex direction-column mt-5' key={i}>
              <Icon icon={card.icon} color='green' size='40px' />
              <h5>{card.title}</h5>
              <p>{card.descripcion}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Characteristics