import React from 'react'
import MainCarousel from '../elements/Carousel'
import { MAIN_CAROUSEL } from '../../constants/json/main-carousel'
import HomeIntroduction from './HomeIntroduction'
import Characteristics from './Characteristics'
import HomeVideo from './HomeVideo'

const Home = () => {
  return (
    <div>
      <MainCarousel images={MAIN_CAROUSEL}/>
      <HomeIntroduction />
      <Characteristics />
      <HomeVideo />
    </div>
  )
}

export default Home