import GlobalModule from './components/modules/GlobalModule';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/index.scss';

function App() {
  return (
    <GlobalModule/>
  );
}

export default App;
