import React from 'react'
import Router from '../../Router'
import { Provider } from 'react-redux';
import store from '../../contexts/redux/redux';
const GlobalModule = () => {
  return (
    <Provider store={store}>
      <Router></Router>
    </Provider>
    
  )
}

export default GlobalModule