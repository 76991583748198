import React from "react";


const Loader = () => {
  return (
    <div id="loader-overlay">
      <div className="spinner-border" role="status"></div>
    </div>
  );
};
export default Loader;
