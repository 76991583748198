import React from 'react'

const Icon = ({icon, color, size}) => {
  return (
    <div className='icon-cgp'>
      <i className={`${icon} ${color}`} style={{ fontSize: size }}></i>
    </div>
    
  )
}

export default Icon